import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import LayoutTopPage from '../components/LayoutTopPage'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  title,
  titleEn,
  intro,
  service,
}) => (
  <div>
    <div className="l-container--wide">
      <div className="p-mainVisual">
        <h1 className="p-mainVisual__copy">
          <span className="p-mainVisual__copyJa">
            {title}
          </span>
          <span className="p-mainVisual__copyEn u-mqHidden--sm">
            {titleEn}
          </span>
        </h1>
        <p className="p-mainVisual__copyVertical">
          <span className="u-font--medium" data-spacing="mr1">
            ARBOR : 
          </span>
          <span className="u-font--light">
            DEVELOPER
          </span>
        </p>
      </div>
    </div>
    <main className="l-main">
      <section className="l-pageSection">
        <div className="c-skewObject--dot" />
        <div className="l-container">
          <h2 className="c-sectionTitle">
            <span className="c-sectionTitle__ja">{intro.heading}</span>
            <span className="c-sectionTitle__en">{intro.headingEn}</span>
          </h2>
          <div className="p-contentList">
              
            <section className="p-contentList__item--right">
              <header className="p-contentList__head--right">
                <span className="p-contentList__num--right">1</span>
                <figure className="p-contentList__image">
                  <img src="/img/home/home_strength1.jpg" alt="" />
                </figure>
              </header>
              <div className="p-contentList__text">
                <h3 className="p-contentList__title--right">
                  <span className="p-contentList__titleJa js-textReplace">
                    {intro.item1.title}
                  </span>
                  <span className="p-contentList__titleEn js-textReplace">
                    {intro.item1.titleEn}
                  </span>
                  <div className="c-triangleObject p-contentList__object--right">
                    <div className="c-triangleObject__large--aquaGreen" />
                    <div className="c-triangleObject__small--gray" />
                  </div>
                </h3>
                <p className="p-contentList__writing">
                  {intro.item1.description}
                </p>
              </div>
            </section>

            <section className="p-contentList__item--left">
              <header className="p-contentList__head--left">
                <span className="p-contentList__num--left">2</span>
                <figure className="p-contentList__image">
                  <img src="/img/home/home_strength2.jpg" alt="" />
                </figure>
              </header>
              <div className="p-contentList__text">
                <h3 className="p-contentList__title--left">
                  <span className="p-contentList__titleJa js-textReplace">
                    {intro.item2.title}
                  </span>
                  <span className="p-contentList__titleEn js-textReplace">
                    {intro.item2.titleEn}
                  </span>
                  <div className="c-triangleObject p-contentList__object--left">
                    <div className="c-triangleObject__large--gray" />
                    <div className="c-triangleObject__small--orange" />
                  </div>
                </h3>
                <p className="p-contentList__writing ">
                  {intro.item2.description}
                </p>
              </div>
            </section>

            <section className="p-contentList__item--right">
              <header className="p-contentList__head--right">
                <span className="p-contentList__num--right">3</span>
                <figure className="p-contentList__image">
                  <img src="/img/home/home_strength3.jpg" alt="" />
                </figure>
              </header>
              <div className="p-contentList__text">
                <h3 className="p-contentList__title--right">
                  <span className="p-contentList__titleJa js-textReplace">
                    {intro.item3.title}
                  </span>
                  <span className="p-contentList__titleEn js-textReplace">
                    {intro.item3.titleEn}
                  </span>
                  <div className="c-triangleObject p-contentList__object--right">
                    <div className="c-triangleObject__large--marineBlue" />
                    <div className="c-triangleObject__small--yellow" />
                  </div>
                </h3>
                <p className="p-contentList__writing">
                  {intro.item3.description}
                </p>
              </div>
            </section>

            <section className="p-contentList__item--left">
              <header className="p-contentList__head--left">
                <span className="p-contentList__num--left">4</span>
                <figure className="p-contentList__image">
                  <img src="/img/home/home_strength4.jpg" alt="" />
                </figure>
              </header>
              <div className="p-contentList__text">
                <h3 className="p-contentList__title--left">
                  <span className="p-contentList__titleJa js-textReplace">
                    {intro.item4.title}
                  </span>
                  <span className="p-contentList__titleEn js-textReplace">
                    {intro.item4.titleEn}
                  </span>
                  <div className="c-triangleObject p-contentList__object--left">
                    <div className="c-triangleObject__large--yellow" />
                    <div className="c-triangleObject__small--gray" />
                  </div>
                </h3>
                <p className="p-contentList__writing">
                  {intro.item4.description}
                </p>
              </div>
              </section>
    
            <section className="p-contentList__item--right">
              <header className="p-contentList__head--right">
                <span className="p-contentList__num--right">5</span>
                <figure className="p-contentList__image">
                  <img src="/img/home/home_strength5.jpg" alt="" />
                </figure>
              </header>
              <div className="p-contentList__text">
                <h3 className="p-contentList__title--right">
                  <span className="p-contentList__titleJa js-textReplace">
                    {intro.item5.title}
                  </span>
                  <span className="p-contentList__titleEn js-textReplace">
                    {intro.item5.titleEn}
                  </span>
                  <div className="c-triangleObject p-contentList__object--right">
                    <div className="c-triangleObject__large--gray" />
                    <div className="c-triangleObject__small--aquaGreen" />
                  </div>
                </h3>
                <p className="p-contentList__writing">
                  {intro.item5.description}
                </p>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section className="l-pageSection p-homeService">
        <div className="c-skewObject--yellow" />
        <div className="l-container" data-spacing="pt3 pb8" data-spacingsp="pb1">
          <h2 className="c-sectionTitle" data-spacing="mb6">
            <span className="c-sectionTitle__ja">{service.heading}</span>
            <span className="c-sectionTitle__en">{service.headingEn}</span>
          </h2>
          <p className="u-lineHeight200 u-whiteSpacePreWrap" data-spacing="mb6">
            {service.description}
          </p>
          <div className="p-homeService__words">
            <p className="p-homeService__word">コストの<br className="u-mqHidden--lg" />最適化</p>
            <p className="p-homeService__word">目的と希望に<br className="u-mqHidden--lg" />コミット</p>
            <p className="p-homeService__word">柔軟な対応</p>
          </div>
          <Link to="/service" className="c-button--skewBorderYellow p-homeService__button">
            詳細はこちら
            <span className="c-icon--arrow c-button__arrow" />
          </Link>
        </div>
      </section>
      <section className="l-pageSection">
        <div className="c-skewObject--homeBlog" />
        <div className="l-container" data-spacing="pt3 pb8" data-spacingsp="pb1">
          <h2 className="c-sectionTitle" data-spacing="mb6">
            <span className="c-sectionTitle__ja">ブログ</span>
            <span className="c-sectionTitle__en">BLOG</span>
          </h2>
          <div data-spacing="pt8" data-spacingsp="pt0">
            <BlogRoll />
            <Link to="/blog" className="c-button--homeBlog">
              ブログ一覧を見る
              <span className="c-icon--arrow c-button__arrow" />
            </Link>
          </div>
        </div>
      </section>
        
    </main>
  </div>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <LayoutTopPage>
      <IndexPageTemplate
        title={frontmatter.title}
        titleEn={frontmatter.titleEn}
        intro={frontmatter.intro}
        service={frontmatter.service}
      />
    </LayoutTopPage>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        titleEn
        intro {
          heading
          headingEn
          item1{
            title
            titleEn
            description
          }
          item1{
            title
            titleEn
            description
          }
          item2{
            title
            titleEn
            description
          }
          item3{
            title
            titleEn
            description
          }
          item4{
            title
            titleEn
            description
          }
          item5{
            title
            titleEn
            description
          }
        }
        service {
          heading
          headingEn
          description
          word1
          word2
          word3
        }
      }
    }
  }
`

import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import NavbarTopPage from '../components/NavbarTopPage'
import '../styles/scss/main.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: title,
      alternateName: title || '',
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
        '@type': 'Person',
        'givenName': '直人',
        'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: description
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": title
          }
        }
      ],
    },
  ];
  
  return (
    <div className="l-wrapper">
      <Helmet>
        <html lang="ja" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Sans+JP:wght@400;500&display=swap" rel="stylesheet" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.png`}
        />
      </Helmet>
      <NavbarTopPage />
      <div style={{
        position: "relative",
        zIndex: 0
      }}>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
